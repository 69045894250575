import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/reimbursement/getList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//制单
export function add(data){
    return service.request({
        url:"/reimbursement/add",
        method: "post",
        data:Qs.stringify(data),
    });
}

//报废
export function remove(data){
    return service.request({
        url:"/reimbursement/remove",
        method: "post",
        data:Qs.stringify(data),
    });
}

//详情
export function details(data){
    return service.request({
        url:"/reimbursement/details",
        method: "post",
        data:Qs.stringify(data),
    });
}

//下载
export function toFileDown(data){
    return service.request({
        url:"/user/fileDown",
        method: "post",
        responseType: 'blob',
        data:Qs.stringify(data),
    });
}
