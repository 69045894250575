<template>
    <div class="html" ref="htmlRef">
        <!-- 查询条件 -->
        <div class="query" ref="queryRef">
            <a-form layout="inline" :model="data.queryData">
                <a-form-item label="用户昵称">
                    <a-input v-model:value="data.queryData.nick_name" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <a-form-item label="角色名称">
                    <a-select allow-clear
                        v-model:value="data.queryData.role_id"
                        show-search
                        placeholder=""
                        :loading="data.isQueryRole"
                        style="width:170px;"
                    >
                        <template v-for="(item,index) in data.downRoleList" :key="index+1">
                            <a-select-option :value="item.id">{{item.roleName}}</a-select-option>
                        </template>
                    </a-select>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button type="primary" @click="toQueryList(true,data.queryData)">搜索</a-button>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button @click="resetTable">重置</a-button>
                </a-form-item>
            </a-form>
        </div>
        <!-- 按钮 -->
        <div class="btn" ref="btnRef">
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.see" @click="userSee">查看</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.add" @click="userAdd">新增</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.start" @click="userStart">启用</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.stop" @click="userStop">停用</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.assgin" @click="userAssgin">分配角色</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.real" @click="userRealName">修改真实姓名</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.reset" @click="userPassword">重置密码</a-button>
        </div>
        <!-- 表格 -->
        <div class="table" :style="data.styleTableHeight" ref="tableRef">
                <cTable
                ref="ctableRef"
                :toTableData="data.tableData"
                :toTableY="data.tableHeight"
                :toColumns="columns"
                :toBordered="true"
                toSize="middle"
                :toTableLoading="data.tableLoading"
                toPageStyle="float: right;"
                :toDataTotal="data.dataTotal"
                @tData="tData"
                @setSortedInfo="setSortedInfo"
                @getSelectedRowKeys="getSelectedRowKeys"
                />
        </div>
    </div>

<!-- 查看 新增 修改 对话框 -->
<a-modal
  :title="data.modalTitle"
  v-model:visible="data.isModal"
  :confirm-loading="data.isModalLoading"
  @ok="handleModal"
  @cancel="handleCloseModal"
  :ok-button-props="{ disabled: data.isOkButton }"
  :cancel-button-props="{ disabled: data.isCancelButton }"
  okText = "确认"
  cancelText= "取消"
  width="700px"
  :maskClosable="false" 
>
<a-spin :spinning="data.isModalLoading">
  <a-form
    ref="modalRef"
    :model="data.modalData"
    :rules="data.modalRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:17}"
  >
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="用户昵称" name="nick_name">
                <a-input v-model:value="data.modalData.nick_name" autocomplete="off" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="真实姓名" name="real_name">
                <a-input v-model:value="data.modalData.real_name" autocomplete="off" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="是否启用" name="is_deleted">
                <a-select v-model:value="data.modalData.is_deleted" placeholder="" :disabled="data.modalType==='see'">
                    <template v-for="(item,index) in data.deletedList" :key="index+1">
                        <a-select-option :value="item.id">{{item.name}}</a-select-option>
                    </template>
                </a-select>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="角色名称" name="role_id">
                <a-select
                    v-model:value="data.modalData.role_id"
                    show-search
                    placeholder=""
                    :disabled="data.modalType==='see'"
                >
                    <template v-for="(item,index) in data.downRoleList" :key="index+1">
                        <a-select-option :value="item.id">{{item.roleName}}</a-select-option>
                    </template>
                </a-select>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="手机号码" name="phone">
                <a-input v-model:value="data.modalData.phone" autocomplete="off" allow-clear :disabled="data.modalType==='see'" />
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="用户账号" name="username">
                <a-input v-model:value="data.modalData.username" autocomplete="off" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row v-if="data.modalType==='add'">
        <a-col :span="12">
            <a-form-item has-feedback label="密码" name="password">
                <a-input v-model:value="data.modalData.password" autocomplete="off" allow-clear />
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="确认密码" name="agPassword">
                <a-input v-model:value="data.modalData.agPassword" autocomplete="off" allow-clear />
            </a-form-item>
        </a-col>
    </a-row>
    <a-row v-if="data.modalType==='see'">
        <a-col :span="12">
            <a-form-item has-feedback label="创建日期" name="time">
                <a-date-picker style="width:100%" v-model:value="data.modalData.time" autocomplete="off" allow-clear :disabled="data.modalType==='see'" />
            </a-form-item>
        </a-col>
    </a-row>
  </a-form>
</a-spin>
</a-modal>
<!-- 角色分配 -->
<a-modal
  title="角色分配"
  v-model:visible="data.isRoleRistribution"
  :confirm-loading="data.isRoleRistributionLoading"
  @ok="handleRoleRistribution"
  @cancel="handleCloseRoleRistribution"
  okText = "确认"
  cancelText= "取消"
  width="400px"
  :maskClosable="false" 
>
    <a-form
        ref="roleRistributionRef"
        :model="data.roleRistributionData"
        :rules="data.RoleRistributionRules"
        :labelCol="{span:6}"
        :wrapperCol="{span:17}"
    >
        <a-row>
            <a-col :span="24">
                <a-form-item has-feedback label="角色名称" name="role_id">
                    <a-select
                        v-model:value="data.roleRistributionData.role_id"
                        show-search
                        placeholder=""
                        :loading="data.isQueryRole"
                    >
                        <template v-for="(item,index) in data.downRoleList" :key="index+1">
                            <a-select-option :value="item.id">{{item.roleName}}</a-select-option>
                        </template>
                    </a-select>
                </a-form-item>
            </a-col>
        </a-row>
    </a-form>
</a-modal>
<!-- 修改真实姓名 -->
<a-modal
  title="修改真实姓名"
  v-model:visible="data.isUpRealName"
  :confirm-loading="data.upRealNameLoading"
  @ok="handleUpRealName"
  @cancel="handleCloseUpRealName"
  okText = "确认"
  cancelText= "取消"
  width="420px"
  :maskClosable="false"
>
  <a-form
    ref="upRealNameRef"
    :model="data.upRealNameData"
    :rules="data.upRealNameRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:16}"
  >

      <a-form-item has-feedback label="真实姓名" name="real_name">
        <a-input v-model:value="data.upRealNameData.real_name" autocomplete="off" allow-clear />
      </a-form-item>
  </a-form>
</a-modal>
</template>

<script>
import { useStore } from 'vuex'
import { reactive,ref,computed,onMounted,createVNode,watch,nextTick } from 'vue'
import cTable from "@/components/table"
import { getList,details,add,startOrStop,roleDistribution,upRealName,resetPassword } from "@/api/admin/user.js"
import { downRoleList } from "@/api/down.js"
import { isOK } from "@/utils/illegal.js"
import { Modal,message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
export default {
   name: "User",
   components: { cTable },
   setup(){
        const store = useStore();
        //监听消息栏 是否显示 用于调整表格高度
        watch(
            () => store.state.user.isHeadBar,
            (count, prevCount) => {
                if(count){
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                }else{
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                }
            }
        )
        //排序样式数据记录
        const sortedInfo = ref();
        //控件传回的排序样式和值
        const setSortedInfo = (value) =>{
            sortedInfo.value = value;
        }
        const columns = computed(() => {
            const sorted = sortedInfo.value || {};
            return [
                {
                    title: '序号',
                    width: 60,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '头像',
                    width: 48,
                    dataIndex: 'salt',
                    key: 'salt',
                    slots: {
                        customRender: 'img',
                    },
                },
                {
                    title: '用户名称',
                    width: 140,
                    dataIndex: 'nickName',
                    key: 'nick_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '角色名称',
                    width: 140,
                    dataIndex: 'roleName',
                    key: 'role_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '手机号码',
                    width: 100,
                    dataIndex: 'phone',
                    key: 'phone',
                    ellipsis: true,
                },
                {
                    title: '是否启用',
                    width: 120,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'is_deleted' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'isDeleted',
                    key: 'is_deleted',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.isDeleted==1?"停用":text.record.isDeleted==0?"启用":"";
                    }
                },
                {
                    title: '创建账号时间',
                    width: 160,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'ugmt_create' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'ugmtCreate',
                    key: 'ugmt_create',
                    ellipsis: true,
                },
                {
                    title: '角色更新时间',
                    width: 160,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'rgmt_modified' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'rgmtModified',
                    key: 'rgmt_modified',
                    ellipsis: true,
                },
                {
                    title: '用户更新时间',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'ugmt_modified' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'ugmtModified',
                    key: 'ugmt_modified',
                    ellipsis: true,
                }
            ];
        });
        const data = reactive({
            styleTableHeight:"height:calc(100% - 104px)",
            btnMenu: store.state.user.btnList.user,
            downRoleList:[],//角色下拉数据
            isQueryRole:false,//角色下拉数据加载中
            queryData:{
                nick_name:"",
                role_id:"",
            },
            tableLoading: false,
            tableData: [],
            tableHeight: 0,
            selectedRowKeys: [],
            dataTotal:0,

            //以下是增删改查按钮事件参数 
            modalTitle:"",
            modalType:"",
            isModal: false,
            isModalLoading: false,
            isOkButton: false,
            isCancelButton: false,
            deletedList:[{id:0,name:"启用"},{id:1,name:"停用"}],//是否启用
            modalData:{
                nick_name:"",
                real_name:"",
                role_id:"",
                is_deleted: "",
                username:"",
                time:"",
                password:"",
                agPassword:"",
                phone:"",
            },
            modalRules:{
                nick_name:[{ required: true, message: '用户昵称不能为空', trigger: 'blur' }],
                real_name:[{ required: true, message: '真实姓名不能为空', trigger: 'blur' }],
                role_id:[{ required: true, message: '角色名称不能为空', trigger: 'change' }],
                is_deleted:[{ required: true,type:'number', message: '是否启用不能为空', trigger: 'change' }],
                username:[{ required: true, message: '用户账户不能为空', trigger: 'blur' }],
                password:[
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 11, message: '密码长度6到11位', trigger: 'blur' },
                ],
                agPassword:[{required:true,message:'确认密码不能为空',trigger:'blur'},
                {
                    validator:(rule,value,callback)=>{
                        if(value===''){
                            callback(new Error('确认密码不能为空'))
                        }else if(value!==data.modalData.password){
                            callback(new Error('两次输入密码不一致'))
                        }else{
                            callback( )
                        }
                    },
                    trigger:'blur'
                }],
                phone:[
                    { required: true, message: '手机号码不能为空', trigger: 'blur' },
                    { min: 11, max: 11, message: '手机号码为11位数字', trigger: 'blur' },
                    {
                        validator:(rule,value,callback)=>{
                            const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
                            if(!regMobile.test(value)){
                                callback(new Error('手机号码不正确'))
                            }else{
                                callback( )
                            }
                        },
                        trigger:'blur'
                    }
                ],
            },

            //以下为角色分配参数
            isRoleRistribution:false,
            isRoleRistributionLoading:false,
            roleRistributionData:{
                role_id:"",
            },
            RoleRistributionRules:{
                role_id:[{ required: true, message: '角色名称不能为空', trigger: 'change' }],
            },

            //以下为修改真实姓名参数
            isUpRealName:false,
            upRealNameLoading:false,
            upRealNameData:{
                real_name:"",
            },
            upRealNameRules:{
                real_name:[{ required: true, message: ' 真实姓名不能为空', trigger: 'change' }],
            },

        });
        //获取表格高度（用于滚轮）
        const tableRef = ref();
        const btnRef = ref();
        const queryRef = ref();
        const htmlRef = ref();
        
        //复选的数据id
        const getSelectedRowKeys = (selectedRowKeys) =>{
            data.selectedRowKeys = selectedRowKeys;
        }

        //调用流程 先跳入table公用控件 然后把数据整体输出回来 再进行查询  state为true时说明是控件的回调
        const ctableRef = ref();
        //跳转到table公用控件 status判断是否是新的搜索
        const toQueryList = (status) =>{
            ctableRef.value.queryList(status,data.queryData);//queryList是cTable子组件里的方法
        }
        const tData = (cont) =>{
            data.tableLoading = true;
            getList({
                page: cont[1].page,
                limit: cont[1].limit,
                role_id: isOK(cont[0].role_id)?cont[0].role_id:"",
                nick_name: isOK(cont[0].nick_name)?cont[0].nick_name:"",
                sort: cont[1].sort,
                desc: cont[1].desc,
            }).then(res =>{
                data.tableLoading = false;
                document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;//搜索后表格回到顶部
                data.tableData = res.data.list;
                data.dataTotal = res.data.total;
            })
        }
        //重置表格数据
        const resetTable = () =>{
            data.queryData.role_id = "";
            data.queryData.nick_name = "";
            //清空表格复选框选中状态
            ctableRef.value.unSelectedRowKeys();
            toQueryList(true);
        }

        //加载角色下拉数据
        const getDownRoleList = () =>{
            data.isQueryRole = true;
            downRoleList().then(res =>{
                data.downRoleList = res.data.list;
                data.isQueryRole = false;
            })
        }

        //渲染后 加载数据
        onMounted(() => {
            //加载搜索 角色下拉数据
            getDownRoleList();
            //加载表格数据
            toQueryList(true);

            nextTick(()=>{
                //延迟加载
                setTimeout(() =>{
                    if(store.state.user.isHeadBar){
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                    }else{
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                    }
                },1);
            })
        });

        //重置modal数据
        const resetModalData = () =>{
            data.modalData = {
                nick_name:"",
                real_name:"",
                role_id:"",
                is_deleted: "",
                username:"",
                time:"",
                password:"",
                agPassword:"",
                phone:"",
            };
        }

        

        //按钮-查看
        const userSee = () =>{
            data.modalTitle = "查看用户";
            data.modalType = "see";
            data.isOkButton = true;
            data.isModalLoading = true;
            //加载角色下拉
            getDownRoleList();
            //判断是否选择一条数据
            if(data.selectedRowKeys.length==1){
                details({id:data.selectedRowKeys[0]}).then(res =>{
                    data.isModalLoading = false;
                    data.modalData.nick_name = res.data.list.nickName;
                    data.modalData.real_name = res.data.list.realName;
                    data.modalData.role_id = res.data.list.roleId;
                    data.modalData.is_deleted = res.data.list.isDeleted?1:0;
                    data.modalData.username = res.data.list.username;
                    data.modalData.time = res.data.list.ugmtCreate;
                    data.modalData.phone = res.data.list.phone;
                    data.isModal = true;
                }).catch((err) => {
                    data.isModalLoading = false;
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可查询多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //按钮-新增
        const modalRef = ref();
        const userAdd = () =>{
            data.modalTitle = "新增用户";
            data.modalType = "add";
            //加载角色下拉
            getDownRoleList();
            resetModalData();
            data.isModal = true;
        }

        //按钮-启用
        const userStart = () =>{
            if(data.selectedRowKeys.length>0){
                    Modal.confirm({
                        title: '启用账户',
                        icon: createVNode(ExclamationCircleOutlined),
                        content: '您是否确定启用账户',
                        okText: '确认',
                        cancelText: '取消',
                        //确定
                        onOk() {
                            startOrStop({idList:data.selectedRowKeys.toString(),type:0}).then(res =>{
                                message.success("启用"+res.data.num+"个账户");
                                resetTable();
                            })
                        },
                        // 取消
                        onCancel() {},
                    });
            }else{
                message.warning("请选择一条数据");
            }
        }

        //按钮-停用
        const userStop = () =>{
            if(data.selectedRowKeys.length>0){
                    Modal.confirm({
                        title: '停用账户',
                        icon: createVNode(ExclamationCircleOutlined),
                        content: '您是否确定停用账户',
                        okText: '确认',
                        cancelText: '取消',
                        //确定
                        onOk() {
                            startOrStop({idList:data.selectedRowKeys.toString(),type:1}).then(res =>{
                                message.success("停用"+res.data.num+"个账户");
                                resetTable();
                            })
                        },
                        // 取消
                        onCancel() {},
                    });
            }else{
                message.warning("请选择一条数据");
            }
        }

        //按钮-分配角色
        const roleRistributionRef = ref();
        const userAssgin = () =>{
            if(data.selectedRowKeys.length==1){
                //加载角色下拉
                getDownRoleList();
                data.isRoleRistribution = true;
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时修改多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }
        //确定-分配角色
        const handleRoleRistribution = () =>{
            if(data.selectedRowKeys.length==1){
                roleRistributionRef.value.validate().then(() => {
                    data.isRoleRistributionLoading = true;
                    roleDistribution({id:data.selectedRowKeys[0],role_id:data.roleRistributionData.role_id}).then(res =>{
                        message.success("分配角色成功");
                        handleCloseRoleRistribution();
                        resetTable();
                    })
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时分配多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }
        //取消-分配角色
        const handleCloseRoleRistribution = () =>{
            data.roleRistributionData.role_id = "";
            data.isRoleRistribution = false;
            data.isRoleRistributionLoading = false;
            //重置表单校验
            roleRistributionRef.value.resetFields();
        }

        //按钮-重置密码
        const userPassword = () =>{
            if(data.selectedRowKeys.length>0){
                    Modal.confirm({
                        title: '重置密码',
                        icon: createVNode(ExclamationCircleOutlined),
                        content: '您是否确定重置密码',
                        okText: '确认',
                        cancelText: '取消',
                        //确定
                        onOk() {
                            resetPassword({idList:data.selectedRowKeys.toString()}).then(res =>{
                                message.success("重置"+res.data.num+"个用户成功");
                                resetTable();
                            })
                        },
                        // 取消
                        onCancel() {},
                    });
            }else{
                message.warning("请选择一条数据");
            }
        }

        //对话框-确定
        const handleModal = () =>{
            modalRef.value.validate().then(() => {
                if(data.modalType === "add"){
                    data.isModalLoading = true;
                    add({
                        nick_name:data.modalData.nick_name,
                        real_name:data.modalData.real_name,
                        role_id:data.modalData.role_id,
                        is_deleted:data.modalData.is_deleted,
                        username:data.modalData.username.replace(/\s+/g, ""),
                        password:data.modalData.password,
                        phone:data.modalData.phone,
                    }).then(res =>{
                        message.success("新增成功");
                        handleCloseModal();
                        resetTable();
                    }).catch((err) => {
                        data.isModalLoading = false;
                    });
                }
            })
        }

        //对话框-取消
        const handleCloseModal = () =>{
            resetModalData();
            data.isModal = false;
            data.isModalLoading = false;
            data.isOkButton = false;
            //重置表单校验
            modalRef.value.resetFields();
        }

        //按钮-修改真实姓名
        const upRealNameRef = ref();
        const userRealName = () =>{
            if(data.selectedRowKeys.length==1){
                data.upRealNameLoading = true;
                details({id:data.selectedRowKeys[0]}).then(res =>{
                    data.upRealNameLoading = false;
                    data.upRealNameData = {
                        id: data.selectedRowKeys[0],
                        real_name: res.data.list.realName,
                    }
                    data.isUpRealName = true;
                }).catch((err) => {
                    data.isModalLoading = false;
                });
                
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时修改多条数据");
            }else{
                message.warning("请选择一条数据");
            }
            
        }

        //对话框-修改真实姓名-确认
        const handleUpRealName = () =>{
            upRealNameRef.value.validate().then(() => {
                upRealName(data.upRealNameData).then(res =>{
                    message.success("修改真实姓名成功");
                    handleCloseUpRealName();
                    resetTable();
                })
            })
        }

        //对话框-修改真实姓名-取消
        const handleCloseUpRealName = () =>{
            data.upRealNameData={
                real_name:"",
            }
            data.isUpRealName = false;
            data.upRealNameLoading = false;
            //重置表单校验
            upRealNameRef.value.resetFields();
        }

        return {
            setSortedInfo,
            columns,
            tableRef,
            btnRef,
            queryRef,
            htmlRef,
            ctableRef,
            data,
            getSelectedRowKeys,
            toQueryList,
            tData,
            resetTable,

            getDownRoleList,

            modalRef,
            roleRistributionRef,
            resetModalData,
            userSee,
            userAdd,
            userStart,
            userStop,
            userAssgin,
            userPassword,

            handleModal,
            handleCloseModal,
            handleRoleRistribution,
            handleCloseRoleRistribution,

            upRealNameRef,
            userRealName,
            handleUpRealName,
            handleCloseUpRealName,
        }
   },
}
</script>
<style lang='scss' scoped>
.html{
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.query{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px 0 20px;
}
.btn{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px;
}
.btn_style{
    margin-right: 12px;
}
.table{
    width: calc(100% - 40px);
    // height: calc(100% - 102px);
    margin-left: 20px;
}
</style>