import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/reimbursement/examineGetList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//审核
export function examine(data){
    return service.request({
        url:"/reimbursement/examine",
        method: "post",
        data:Qs.stringify(data),
    });
}

//详情
export function details(data){
    return service.request({
        url:"/reimbursement/details",
        method: "post",
        data:Qs.stringify(data),
    });
}

//发送短信
export function SMS(data){
    return service.request({
        url:"/reimbursement/SMS",
        method: "post",
        data:Qs.stringify(data),
    });
}

//下载
export function toFileDown(data){
    return service.request({
        url:"/user/fileDown",
        method: "post",
        responseType: 'blob',
        data:Qs.stringify(data),
    });
}
