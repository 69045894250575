<template>
<!-- <img src="../../assets/images/1.png" hidden>
<img src="../../assets/images/2.png" hidden>
<img src="../../assets/images/3.png" hidden>
<img src="../../assets/images/title1.png" hidden>
<img src="../../assets/images/title2.png" hidden>
<img src="../../assets/images/title3.png" hidden>
  <div class="account"  :style="'background-image:'+data.backgroundImageURL+';'">
    <transition name="allTransition">
    <div class="login" v-show="data.isLogin">
        <div class="login_title" :style="'background-image:'+data.titleBackgroundImageURL+';'"></div>
        <div class="login_cont" >
          <a-form ref="loginForm" :model="data.login" :rules="data.rules" :wrapper-col="{span: 24}">
            <a-form-item label="" name="username">
              <a-input ref="usernameRef" v-focus v-model:value="data.login.username" placeholder="请输入账号..." class="inputStyle" @pressEnter="userLogin" />
            </a-form-item>
            <a-form-item label="" name="password">
              <a-input type="password" v-model:value="data.login.password" placeholder="请输入密码..." class="inputStyle" @pressEnter="userLogin" />
            </a-form-item>
            <a-row :gutter="24">
              <a-col :span="16">
                <a-form-item label="" name="code">
                  <a-input v-model:value="data.login.code" placeholder="验证码" class="inputStyle" @pressEnter="userLogin" />
                </a-form-item>
              </a-col>
              <a-col :span="8" style="padding:0 12px 0 0;">
                <div class="code" title="点击更换验证码">
                  <a-image @click="refreshCode" :src="data.codeUrl" :fallback="data.codeLoadImg" height="44px" :preview="false" />
                </div>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="24">
                <a-button style="height:44px"  type="primary" shape="round" :loading="data.btnLoading" block size="large" @click="userLogin()">登录</a-button>
              </a-col>
            </a-row>
          </a-form>
        </div>
    </div>
    </transition>
  </div> -->

  <div class="html">
    <div class="logo">
      <div class="logo_img"></div>
      <div class="logo_title">昱希塑业</div>
    </div>
    <div class="cont">
      <div class="cont_in">
        <a-form ref="loginForm" :model="data.login" :rules="data.rules" :wrapper-col="{span: 24}">
          <a-form-item label="" name="username">
            <a-input v-focus v-model:value="data.login.username" style="height:44px;" placeholder="请输入账号..." @pressEnter="userLogin" />
          </a-form-item>
          <a-form-item label="" name="password">
            <a-input type="password" v-model:value="data.login.password" placeholder="请输入密码..." style="height:44px;" @pressEnter="userLogin" />
          </a-form-item>
          <a-row :gutter="24">
              <a-col :span="16">
                <a-form-item label="" name="code">
                  <a-input v-model:value="data.login.code" placeholder="验证码" style="height:44px;" @pressEnter="userLogin" />
                </a-form-item>
              </a-col>
              <a-col :span="8" style="padding:0 12px 0 0;" align="middle">
                <div class="code" title="点击更换验证码">
                  <a-image @click="refreshCode" :src="data.codeUrl" :fallback="data.codeLoadImg" height="44px" :preview="false" />
                </div>
              </a-col>
          </a-row>
          <a-row :gutter="24">
              <a-col :span="24">
                <a-button style="height:44px" type="primary" :loading="data.btnLoading" block size="large" @click="userLogin()">登录</a-button>
              </a-col>
          </a-row>
        </a-form>
        <div class="title">密码遗忘，请联系管理员</div>
      </div>
    </div>
    <!-- 动弹区域 -->
    <transition name="allTransition">
      <div class="hello" v-show="data.isLogin">欢迎光临</div>
    </transition>
    <!-- 底部 -->
    <div class="foot">台州市昱希塑业有限公司 ©2021-{{new Date().getFullYear()}} <a class="foota" href="https://beian.miit.gov.cn" target ="_blank">浙ICP备 2021012061号</a></div>
  </div>
</template>

<script>
//预加载图片
// import { setImgs } from "@/utils/loadingImg";
import { onMounted, reactive,ref } from "vue";
import { useRouter } from 'vue-router';
// 局部组件（导入）
// import Captcha from "@/components/Captcha";
import { message } from 'ant-design-vue';
import { login } from "@/api/login.js"
import { setToKen } from "@/utils/app.js"
import { isOK,isEncryption } from "@/utils/illegal.js"



const codeUrl = process.env.VUE_APP_FALG + "/index/getCode";
export default {
  name: "Login",
  components: {  },
  setup(props){
    //预加载图片
    // setImgs([
    //   require("../../assets/images/1.png"),
    //   require("../../assets/images/2.png"),
    //   require("../../assets/images/3.png"),
    //   require("../../assets/images/title1.png"),
    //   require("../../assets/images/title2.png"),
    //   require("../../assets/images/title3.png"),
    // ]);
    const router = useRouter();
    
    

    const data = reactive({  // 类似于JSON对象的语法
      // layout: {
      //   labelCol: { span: 10 },
      //   wrapperCol: { span: 14 },
      // }
      codeLoadImg: require("../../assets/loading.gif"),
      codeUrl:codeUrl,
      isLogin:false,
      // backgroundImageTimer: "",
      // backgroundImageURL: "url("+require("../../assets/images/1.png")+")",
      // titleBackgroundImageURL: "url("+require("../../assets/images/title1.png")+")",
      btnLoading: false,
      login:{
        username:"",
        password:"",
        code:"",
      },
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 3, max: 15, message: '账号长度3到15位', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 11, message: '密码长度6到11位', trigger: 'blur' },
        ],
        code:[{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    })
    // const data = toRefs(formConfig);

    //修改登录页面的图片方法
    // const upImgUrl =() =>{
    //   let i = 1;
    //   data.backgroundImageTimer = setInterval(()=>{
    //     if(i<4){
    //       let url = "url("+require("../../assets/images/"+i+".png")+")";
    //       let titleurl = "url("+require("../../assets/images/title"+i+".png")+")"; 
    //       data.backgroundImageURL = url; 
    //       data.titleBackgroundImageURL = titleurl;
    //       i++;
    //     }else{
    //       i=1;
    //       let url = "url("+require("../../assets/images/"+i+".png")+")";
    //       let titleurl = "url("+require("../../assets/images/title"+i+".png")+")"; 
    //       data.backgroundImageURL = url;
    //       data.titleBackgroundImageURL = titleurl;  
    //     }
    //   },5*1000)
    // }

    //刷新验证码
    const refreshCode = () =>{
      data.codeUrl = data.codeUrl + '?d='+Math.random();
    }

    //登录
    let loginForm = ref();
    function userLogin() {
      loginForm.value.validate().then(() => {
        data.btnLoading=true;
        login({ username: data.login.username, password: isEncryption(data.login.password),code:data.login.code }).then(res => {
          data.btnLoading = false;
          //将token 存入浏览器cookie
          setToKen(res.data.token);
          // sessionStorage.setItem('token',res.data.token)
          // console.log(sessionStorage.getItem('token'));
          router.push(isOK(localStorage.getItem("selectedKeys"))?localStorage.getItem("selectedKeys"):"/home");
        }).catch((err) => {
          data.btnLoading=false;
          refreshCode();
          message.warning(err.message);
        });
      })
    }
    

    //渲染后加载
    onMounted(() => {
      setTimeout(() => {
        data.isLogin = true;
      }, 100);
      // upImgUrl();
    })
    
    
    return {
      data,
      refreshCode,
      userLogin,
      loginForm,
    }
  },
  onBeforeUnmount(){
    // clearInterval(data.backgroundImageTimer);
  }
};
</script>
<style lang="scss" scoped>
// @import "./style.scss";
// .inputStyle{
//   width: 100%;
//   height: 44px;
//   border: 2px #3e84fd solid !important;
//   border-radius: 22px;
//   padding: 0 15px;
//   font-size: 16px !important;
//   text-align: center;
//   color: #3f68fe;
// }
// .inputStyle:hover{
//   box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
// }
.code{
  width: 100%;
  height: 44px;
  // border-radius: 22px !important;
  overflow: hidden;
}
.code img{
  text-align: center;
}
.html{
  width: 100%;
  height: 100%;
  background-image: url("https://yxerp.oss-cn-hangzhou.aliyuncs.com/erp/loginbj.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.logo{
  position:fixed;
  z-index: 1;
  top: 8%;
  left: 7%;
  width: auto;
  height: 60px;
}
.logo_img{
  width: 60px;
  height: 60px;
  float: left;
  background-image: url("../../assets/loginlogo.png");
  background-repeat: no-repeat;
}
.logo_title{
  width: auto;
  height: 60px;
  line-height: 60px;
  float: left;
  font-size: 40px;
  color: #fff;
  font-weight: bold;
  margin-left: 10px;
}
.cont{
  position:fixed;
  z-index: 2;
  top: calc((100% - 310px)/2);
  right: 15%;
  width: 330px;
  height: 310px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 1px rgba(67,64,64,0.75);
-webkit-box-shadow: 0px 0px 6px 1px rgba(67,64,64,0.75);
-moz-box-shadow: 0px 0px 6px 1px rgba(67,64,64,0.75);
}
.cont_in{
  width: 290px;
  height: 270px;
  margin: 20px;
}
.hello{
  position:fixed;
  z-index: 1;
  top: calc((100% - 270px)/2);
  right: calc(15% + 326px);
  width: 200px;
  height: 44px;
  border-radius: 4px 0px 0px 4px;
  background-image: url("https://yxerp.oss-cn-hangzhou.aliyuncs.com/erp/bar.jpg");
  background-repeat: no-repeat;
  background-position: center;
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 44px;
}
.title{
  width: 100%;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  color: #3f74fe;
  text-align: right;
}
.foot{
  position:fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  text-align: center;
  color: rgb(196, 195, 195);
}
.foota{
  text-decoration: none;
  color: #c4c3c3;
}
.foota:hover{
  text-decoration:underline;
}

//用于也在全部淡入淡出
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.allTransition-enter-active {
  transition: all 1s ease-out;
}

.allTransition-leave-active {
  transition: all 1s cubic-bezier(1, 0.5, 0.8, 1);
}

.allTransition-enter-from,
.allTransition-leave-to {
  transform: translateX(200px);
  opacity: 0;
}
</style>
