import store from "../store";
import router from "./index";
import { getStaticRouter } from "./staticRouter.js";
import { createRouter,createWebHashHistory } from "vue-router";
import { getToken,removeToken } from "@/utils/app.js";
import { message } from 'ant-design-vue';
import { userInfoAll,logout } from "@/api/login.js";
import { arrayIsEqual } from "@/utils/arrayIsEqual.js"
import { getTrees } from "@/utils/tree.js"
import { isOK } from "@/utils/illegal.js"
//声明白名单 
const whiteRouter = ["/login"];

//路由改变的时候 就会触发
//to 下一个页面（进入的页面） from 离开前的页面（当前的页面） next 进入下一个钩子
router.beforeEach((to, from, next) => {
    console.log("进入路由守卫")
    // 根据有没有token判断是否登录
    if(getToken()){
        //token存在
        //判断如果手动进入登录页面，直接跳转到平台首页
        if(to.path==="/login"){
            next("/home");
        }else{
            //进行查询用户数据
            userInfoAll().then(res => {
                let permissionIdist = [];
                let permissionList = res.data.permissionList;
                //为了检查速度加快，只需要检查id
                for(let i = 0;i<permissionList.length;i++){
                    permissionIdist.push(permissionList[i].id);
                }
                //判断是否要进行 权限变更判断
                if(store.state.user.isKey){
                    //检测权限是否变更 将对象转成数组
                    let oldPermissionIdist = [];
                    for(let i = 0;i < store.state.user.permissionList.length;i++){
                        oldPermissionIdist.push(store.state.user.permissionList[i]);
                    }
                    // console.log("oldPermissionIdist",oldPermissionIdist);
                    // console.log("permissionIdist",permissionIdist);
                    if(arrayIsEqual(oldPermissionIdist,permissionIdist)){
                        //先判断这个path是否已经存在tabs列表中，如果存在将selectKey修改为这个 不在就添加
                        let tabList = store.state.user.tabList;
                        for(let item of tabList){
                            if(item.path===to.path){
                                store.commit('user/SET_TABSELECTED', item.path);
                                localStorage.setItem("tabSelected", item.path);
                                next();
                                return;
                            }
                        }
                        //不存在tabs列表中，添加tabs
                        store.commit('user/ADD_TABLIST', {path:to.path,name:to.meta.title});
                        localStorage.setItem("tabList", JSON.stringify(store.state.user.tabList));
                        //修改当前选中的selectKey
                        store.commit('user/SET_TABSELECTED', to.path);
                        localStorage.setItem("tabSelected", store.state.user.tabSelected);
                        next();
                    }else{
                        //页面提示信息
                        message.warning("权限变更，请重新登录");
                        //删除全部store和localStorage内容
                        store.commit('user/OUT_SYSTEM');
                        //清除全部缓存
                        localStorage.clear();
                        logout().then(data => {
                            //退出 清除token 
                            removeToken();
                            next("/login");
                        });
                    }
                }else{// 不检查权限 直接重新赋值权限
                    store.commit('user/SET_USER', res.data.user);
                    store.commit('user/SET_ROLES', res.data.roles);
                    store.commit('user/SET_PERMISSIONLIST', permissionIdist);
                    // 重置当前router的match = 初始router.match
                    router.match = createRouter({
                        history: createWebHashHistory(),
                        routes: getStaticRouter()
                      });
                    //进行动态绑定路由
                    //先去掉按钮数据 并重新排列内容 控制台必须要加
                    //重置按钮是否开启参数
                    store.commit('user/SET_BTN_RESET');
                    let pListAll = [];
                    for(let i = 0;i<permissionList.length;i++){
                        //type==2为按钮 判断是否开始对应页面的按钮
                        if(permissionList[i].type===2){
                            store.commit('user/SET_BTN', permissionList[i].permissionValue);
                            // store.commit('user/SET_BTN_ROLE', permissionList[i].permissionValue);
                            // store.commit('user/SET_BTN_MENU', permissionList[i].permissionValue);
                            // store.commit('user/SET_BTN_LOG', permissionList[i].permissionValue);
                        }
                        let nameList = permissionList[i].path.split('/');
                        //type==1为菜单
                        if(permissionList[i].type===1){
                            pListAll.push({
                                id: permissionList[i].id,
                                pid: permissionList[i].pid,
                                path: permissionList[i].path,
                                hidden: permissionList[i].status==1?false:true,
                                name: nameList.length==0?"":nameList[nameList.length-1],
                                meta:{
                                    title: permissionList[i].name,
                                    icon: permissionList[i].icon
                                },
                                component: () => require(`@/views${permissionList[i].component}`),
                            });
                        }
                    }
                    
                    //进行层极处理
                    let list = getTrees(pListAll,0);
                    //将控制台加入到路由
                    list.unshift({
                        path: "/index",
                        name: "Index",
                        meta: {
                        title: "控制台1",
                        icon: "index"
                        },
                        redirect: '/home',
                        component: () => require("@/views/layout/Index.vue"),
                        children:[
                        {
                            path:"/home",
                            name:"Home",
                            meta:{
                            title:"控制台",
                            icon:"home"
                            },
                            component: () => require("@/views/home/Index.vue"),
                        }
                        ]
                    });
                    //最后追加404页面 必需放在最后
                    list.push(
                        {
                            path: "/:pathMatch(.*)*",
                            name: "404",
                            hidden: true,
                            meta: {
                                title: "404"
                            },
                            component: () => import("@/views/error/404.vue")
                        }
                    )
                    //赋值给菜单 并更新
                    routerPackag(list)
                    //获取localStorage中的tabList 和 tabSelected
                    let tabList = isOK(localStorage.getItem("tabList"))?JSON.parse(localStorage.getItem("tabList")):[{path:"/home",name:"控制台"}];
                    let tabSelected = isOK(localStorage.getItem("tabSelected"))?localStorage.getItem("tabSelected"):"/home";
                    // store.commit('user/DEL_ALL_TABLIST');
                    store.commit('user/COPE_TABLIST', tabList);
                    store.commit('user/SET_TABSELECTED', tabSelected);
                    if(tabSelected==="/home"){
                        //默认选择控制台
                        localStorage.setItem("selectedKeys", "/home");
                    }
                    //开启权限变更查询
                    store.commit('user/SET_ISKEY', true);
                    if(tabSelected==="/home"){
                        next({ ...to, replace: true })
                    }else{
                        next(tabSelected)
                    }
                    
                }
            });
        }
    }else{
        //indexOf 寻找whiteRouter里面是否存在to.path的路径 不存在为-1
        if(whiteRouter.indexOf(to.path)!==-1){
            if(isOK(store.state.user.tabSelected)){
                //页面提示信息
                message.warning("登录过期，请重新登录");
            }
            //删除全部store和localStorage内容
            store.commit('user/OUT_SYSTEM');
            //清除全部缓存
            localStorage.clear();
            // window.localStorage.removeItem('tabSelected');
            // window.localStorage.removeItem('tabList');
            // window.localStorage.removeItem('selectedKeys');
            // window.localStorage.removeItem('openKeys');
            next();
        }else{
            next("/login");
        }
    }
});

const routerPackag = routers => {
    routers.filter(itemRouter => {
        //添加路由 一条一条添加
        router.addRoute(itemRouter);
    });
    //路由更新
    router.options.routes = routers;
};