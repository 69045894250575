<template>
    <div class="html" ref="htmlRef">
        <!-- 查询条件 -->
        <div class="query" ref="queryRef">
            <a-form layout="inline" :model="data.queryData">
                <a-form-item label="报销单名称">
                    <a-input v-model:value="data.queryData.name" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <a-form-item label="报销单编号">
                    <a-input v-model:value="data.queryData.reimbursement_id" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <a-form-item label="文件名称">
                    <a-input v-model:value="data.queryData.excel_name" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <!-- <a-form-item label="客户名称">
                    <a-input v-model:value="data.queryData.customer" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item> -->
                <a-form-item label="销售单状态">
                    <a-select allow-clear
                        v-model:value="data.queryData.status"
                        placeholder=""
                        :loading="data.isQueryRole"
                        style="width:170px;"
                    >
                        <template v-for="(item) in data.statusList" :key="item.id">
                            <a-select-option :value="item.id">{{item.name}}</a-select-option>
                        </template>
                    </a-select>
                </a-form-item>
                <a-form-item label="是否报废">
                    <a-select allow-clear
                        v-model:value="data.queryData.is_deleted"
                        placeholder=""
                        style="width:200px;"
                    >
                        <a-select-option :value="0">否</a-select-option>
                        <a-select-option :value="1">是</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button type="primary" @click="toQueryList(true,data.queryData)">搜索</a-button>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button @click="resetTable">重置</a-button>
                </a-form-item>
            </a-form>
        </div>
        <!-- 按钮 -->
        <div class="btn" ref="btnRef">
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.see" @click="reimbursementSee">{{data.btnMenu.examine?'审核':'查看'}}</a-button>
            <a-popconfirm  v-if="!data.btnMenu.examine"
                title="您是否确定发送?"
                ok-text="确定"
                cancel-text="取消"
                @confirm="reimbursementSMS"
            >
                <a-button class="btn_style" type="primary" ghost>发送短信</a-button>
            </a-popconfirm>
        </div>
        <!-- 表格 -->
        <div class="table" :style="data.styleTableHeight" ref="tableRef">
                <cTable
                ref="ctableRef"
                :toTableData="data.tableData"
                :toTableY="data.tableHeight"
                :toColumns="columns"
                :toBordered="true"
                toSize="middle"
                :toTableLoading="data.tableLoading"
                toPageStyle="float: right;"
                :toDataTotal="data.dataTotal"
                @tData="tData"
                @setSortedInfo="setSortedInfo"
                @getSelectedRowKeys="getSelectedRowKeys"
                @toRAR="toRAR"
                />
        </div>
    </div>

<!-- 制单 -->
<a-modal
  :title="data.modalTitle"
  v-model:visible="data.isModal"
  :confirm-loading="data.isModalLoading"
  @ok="handleModal"
  @cancel="handleCloseModal"
  :ok-button-props="{ disabled: data.isOkButton }"
  :cancel-button-props="{ disabled: data.isCancelButton }"
  okText = "确认"
  cancelText= "取消"
  width="1400px"
  :maskClosable="false" 
>
    <a-spin :spinning="data.isModalLoading">
        <div class="reimbursementName">
            <a-form
                ref="modalRef"
                :model="data.modalData"
                :rules="data.modalRules"
                :labelCol="{span:4}"
                :wrapperCol="{span:20}"
            >
                <a-row>
                    <a-col :span="24">
                        <a-form-item has-feedback label="报销单名称" name="name" :wrapperCol="{ style: 'width: calc(100% - 100px)' }" :labelCol="{style: 'width: 100px'}">
                            <a-input v-model:value="data.modalData.name" allow-clear/>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
        </div>
        <div class="excel">
            <div class="excelLeft" style="margin:0px;padding:0px;width:1098px;height: 800px;">
                <iframe
                    v-if="data.fileExcelUrl.length>0"
                    :src="data.fileExcelUrl"
                    frameborder="0"
                    width="1098px"
                    height="800px"
                ></iframe>
                <div v-if="data.fileExcelUrl.length<=0" style="margin:0px;padding:0px;width:1098px;height: 800px;line-height: 800px;text-align: center;font-size: 3rem;color: #eee;">请上传报销单Excel</div>
            </div>
            <div class="excelRight">
                <div class="excelRightTop">
                    <div class="excelBtn">
                        <div class="excelBtnIn" style="width: 238px;">
                            <a-upload
                                v-model:file-list="data.fileExcelList"
                                name="avatar"
                                action="/erp/user/uploadImgFile"
                                :headers="data.headers"
                                @change="handleChangeExcel"
                                :remove="handleRemove"
                            >
                                <a-button>
                                    上传Excel
                                </a-button>
                            </a-upload>
                        </div>
                        <div class="excelBtnIn" style="margin-left: -132px;width: 132px;">
                            <a-button type="dashed" @click="toExcel">下载报销单模板</a-button>
                        </div>
                    </div>
                </div>
                <div class="excelRightImg">
                    <a-upload
                    name="avatar"
                    action="/erp/user/uploadImgFile"
                    :headers="data.headers"
                    list-type="picture-card"
                    v-model:file-list="data.fileImgList"
                    @change="handleChangePreview"
                    :before-upload="handleChangeBeforeUpload"
                    @preview="handlePreview"
                    :remove="handlePreviewRemove"
                    >
                        <div v-if="data.fileImgList.length < 100" style="width: 218px;">
                            <plus-outlined />
                            <div class="ant-upload-text">添加报销单材料</div>
                        </div>
                    </a-upload>
                    <a-modal :visible="data.previewVisible" :footer="null" @cancel="handleImgCancel">
                        <img  width="500" height="400" :src="data.previewImage" />
                    </a-modal>
                </div>
            </div>
        </div>
        <div class="remark">
            <a-row>
                <a-col :span="24">
                    <a-form-item has-feedback label="备注" name="" :wrapperCol="{ style: 'width: calc(100% - 42px)' }" :labelCol="{style: 'width: 42px'}">
                        <a-textarea
                        v-model:value="data.modalData.remarks"
                        placeholder="..."
                        auto-size
                        allow-clear
                        />
                    </a-form-item>
                </a-col>
            </a-row>
        </div>
    </a-spin>
</a-modal>
<!-- 详情 -->
<a-modal
    title="报销单详情"
    v-model:visible="data.isReimbursementDetails"
    width="100%"
    :maskClosable="false"
    :confirm-loading="data.isReimbursementDetailsLoading"
    :footer="footer"
    @cancel="handleCloseDetailsModal"
>
<a-spin :spinning="data.isReimbursementDetailsLoading">
    <div class="details">
        <div class="detailsLeft">
            <!-- https://view.officeapps.live.com/op/view.aspx?src= -->
                <iframe v-if="data.reimbursementDetailsData.excelUrl.length>0"
                    :src="'https://view.officeapps.live.com/op/embed.aspx?src='+encodeURIComponent(data.reimbursementDetailsData.excelUrl)+ '?t='+Date.now()"
                    frameborder="0"
                    width="100%"
                    height="670px"
                ></iframe>
            <div v-if="data.reimbursementDetailsData.excelUrl.length<=0" style="margin:0px;padding:0px;width:1098px;height: 670px;line-height: 670px;text-align: center;font-size: 3rem;color: #eee;">无法加载报销单Excel</div>
        </div>
        <div class="detailsRight">
            <div class="detailsRightName">报销单名称：{{ data.reimbursementDetailsData.name }}</div>
            <div class="detailsRightRAR" v-if="(data.reimbursementDetailsData.bigfileName!=null && data.reimbursementDetailsData.bigfileName!='')" @click="toRAR(data.reimbursementDetailsData.bigfileName,data.reimbursementDetailsData.bigfileUrl,2)">存在压缩包： {{ data.reimbursementDetailsData.bigfileName }}</div>
            <div class="detailsRightRemark">报销单备注：{{ data.reimbursementDetailsData.remark }}</div>
            <div class="detailsRightImg" v-for="(item,index) in data.reimbursementDetailsData.imageList.split('丨')" :key="index+1">
                <img :src="item" width="700" style="margin-top: 16px;"/>
            </div>
        </div>
    </div>
</a-spin>
<template #footer>
        <!-- <a-popconfirm
            title="您是否确定报废?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleScrapModal"
        >
            <a-button key="scrap" type="primary" :loading="data.isOrderReviewLoading">报废</a-button>
        </a-popconfirm> -->
        <a-popconfirm
            title="您是否确定通过?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="toReimbursementExamine(2)" 
        >
            <a-button key="pass" type="primary" v-if="data.btnMenu.examine && data.oneType==1 && !data.isDeleted" :loading="data.isReimbursementDetailsLoading" :disabled="!(data.oneType==1)">通过</a-button>
        </a-popconfirm>
        <a-popconfirm
            title="您是否确定通过审查?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="toReimbursementExamine(1)"
        >
            <a-button key="scrap" type="primary" v-if="data.btnMenu.reject && data.oneType==0 && !data.isDeleted" style="margin-left: 8px;" :loading="data.isReimbursementDetailsLoading" :disabled="!(data.oneType==0)">通过审查</a-button>
        </a-popconfirm>
        <a-popconfirm
            title="您是否确定驳回?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="toReimbursementExamine(3)"
        >
            <a-button key="scrap" type="primary" v-if="(data.btnMenu.reject && data.oneType==0) && !data.isDeleted" style="margin-left: 8px;" :loading="data.isReimbursementDetailsLoading" :disabled="!(data.oneType==0)">驳回</a-button>
        </a-popconfirm>
        <a-popconfirm
            title="您是否确定驳回?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="toReimbursementExamine(3)"
        >
            <a-button key="scrap" type="primary" v-if="(data.btnMenu.examine && data.oneType==1) && !data.isDeleted" style="margin-left: 8px;" :loading="data.isReimbursementDetailsLoading" :disabled="(data.oneType==2 || data.oneType==3 || data.oneType==4)">驳回</a-button>
        </a-popconfirm>
        <a-popconfirm
            title="您是否确定修改为已报销?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="toReimbursementExamine(4)"
        >
            <a-button key="scrap" type="primary" v-if="!data.btnMenu.examine && data.btnMenu.reject && data.oneType!=0 && data.oneType!=1 && data.oneType!=3 && data.oneType!=4 && !data.isDeleted" style="margin-left: 8px;" :loading="data.isReimbursementDetailsLoading" :disabled="!(data.oneType==2)">已报销</a-button>
        </a-popconfirm>

        <a-button key="back" @click="handleCloseDetailsModal" style="margin-left: 8px;">取消</a-button>
      </template>
</a-modal>
</template>

<script>
import axios from "axios";
import { getToken } from "@/utils/app.js";
import { useStore } from 'vuex'
import { deleteAvatar } from "@/api/head.js";
import { reactive,ref,computed,onMounted,createVNode,watch,nextTick,defineComponent } from 'vue'
import cTable from "@/components/table"
import { PlusOutlined } from '@ant-design/icons-vue';
import { getList,details,examine,SMS,toFileDown } from "@/api/reimbursement/reimbursementExamine.js"
import { downProduct } from "@/api/down.js"
import { isOK } from "@/utils/illegal.js"
import { getYyyyddmm } from "@/utils/time.js"
import { getPdf } from "@/utils/htmlToPdf.js"
import { Modal,message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
   name: "ReimbursementExamine",
   components: { cTable,PlusOutlined },
   setup(){
       const store = useStore();
        //监听消息栏 是否显示 用于调整表格高度
        watch(
            () => store.state.user.isHeadBar,
            (count, prevCount) => {
                if(count){
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                }else{
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                }
            }
        )
        //排序样式数据记录
        const sortedInfo = ref();
        //控件传回的排序样式和值
        const setSortedInfo = (value) =>{
            sortedInfo.value = value;
        }
        //页面的表格列属性
        const columns = computed(() => {
            const sorted = sortedInfo.value || {};
            return [
                {
                    title: '序号',
                    width: 60,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '报销单名称',
                    width: 200,
                    dataIndex: 'name',
                    key: 'name',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '报销单编号',
                    width: 200,
                    dataIndex: 'reimbursementId',
                    key: 'reimbursement_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '报销单文件名称',
                    width: 200,
                    dataIndex: 'excelName',
                    key: 'excel_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'excel',
                    },
                },
                {
                    title: '打包文件',
                    width: 200,
                    dataIndex: 'bigfileName',
                    key: 'bigfile_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'bigfile',
                    },
                },
                {
                    title: '报销单材料',
                    width: 300,
                    dataIndex: 'imageList',
                    key: 'image_list',
                    slots:{
                        customRender: 'imgList'
                    }
                },
                {
                    title: '报销单状态',
                    width: 160,
                    dataIndex: 'status',
                    key: 'status',
                    customRender: (text) => {
                        let v = "";
                        switch(text.record.status){
                            case 0: v = "财务未审查，未审核"; break;
                            case 1: v = "财务已审查，未审核"; break;
                            case 2: v = "批准"; break;
                            case 3: v = "驳回"; break;
                            case 4: v = "已报销"; break;
                            default:"";      
                        }
                        return v;
                    },
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '创建时间',
                    width: 180,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'gmt_create' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                },
                {
                    title: '是否报废',
                    width: 90,
                    dataIndex: 'isDeleted',
                    key: 'is_deleted',
                    ellipsis: true,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'is_deleted' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    slots: {
                        customRender: 'isDeleted',
                    },
                },
                {
                    title: '备注',
                    dataIndex: 'remark',
                    key: 'remark',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
            ];
        });
        
        const headers = {
            authorization: 'authorization-text',
        };
        const data = reactive({
            styleTableHeight:"height:calc(100% - 104px)",
            btnMenu: store.state.user.btnList.reimbursementExamine,
            queryData:{
                name:"",
                reimbursement_id:"",
                excel_name:"",
                status:"",
                is_deleted:0,
            },
            headers: {
                'token': getToken()
            },
            fileExcelList:[],//excel
            fileExcelUrlNew:"",//传递给后端的url
            fileExcelUrl:"",//excel回调的地址
            fileExcelName:"",//excel回调的名称
            fileImgList:[],//img
            previewVisible:false,
            previewImage:"",
            tableLoading: false,
            tableData: [],
            tableHeight: 0,
            selectedRowKeys: [],
            dataTotal:0,
            statusList:[
                {id:0,name:"财务未审查，未审核"},
                {id:1,name:"财务已审查，未审核"},
                {id:2,name:"批准"},
                {id:3,name:"驳回"},
                {id:4,name:"已报销"}
            ],
            isOpenDddProductIdSelect:false,
            excelOpen:{
                "info": {
                    "appversion": "14.0300 ",
                    "company": "",
                    "createdTime": "2020-11-10T02:35:00Z",
                    "creator": "Apache POI",
                    "lastmodifiedby": "SSRS",
                    "modifiedTime": "2023-07-11T02:54:58Z",
                    "name": null
                },
                "sheets": [{
                    "celldata": [{
                        "c": 0, 
                        "r": 0, 
                        "v": {
                            "bg": "#FFFFFF",
                            "bl": 1,
                            "ct": {
                                "fa": "General",
                                "t": "s"
                            },
                            "ff": "微软雅黑",
                            "fs": 18,
                            "ht": 0,
                            "qp": 1,
                            "tb": 2,
                            "v": "单元格内容",
                            "vt": 0
                        }
                    }],
                    "config": {},
                    "index": "3", 
                    "name": "XXXXXX", 
                    "order": "0", 
                    "showGridLines": "1", 
                    "status": "1"
                }]
            },
            isPass:false,//是否可以修改状态为 已报销
            oneType:0,//详情报销单的状态
            isDeleted:true,//详情报销单是否报废

            //以下是增删改查按钮事件参数 
            modalTitle:"",
            modalType:"",
            isModal: false,
            isModalLoading: false,
            isOkButton: false,
            isCancelButton: false,
            isProductList:false,//加载产品下拉
            productList:[],//产品下拉
            selectProduct:{},//选中的产品
            tableProductList:[],//选中的产品table列表
            addModalData:{
                product_id:"",
                plan_quantities:"",
                plan_date:"",
            },
            addModalRules:{
                product_id:[{ required: true, message: '请选择产品', trigger: 'change' }],
                plan_quantities:[{ required: true,type:'number', message: '计划生产数不能为空', trigger: 'blur' }],
                plan_date:[{ required: true, message: '计划完成日期不能为空', trigger: 'change' }],
            },
            modalData:{
                name:"",
                remarks:"",
            },
            modalRules:{
                // customer:[{ required: true, message: '客户名称不能为空', trigger: 'blur' }],
                // customer_phone:[
                //     { required: true, message: '手机号码不能为空', trigger: 'blur' },
                //     { min: 11, max: 11, message: '手机号码为11位数字', trigger: 'blur' },
                //     {
                //         validator:(rule,value,callback)=>{
                //             const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
                //             if(!regMobile.test(value)){
                //                 callback(new Error('手机号码不正确'))
                //             }else{
                //                 callback( )
                //             }
                //         },
                //         trigger:'blur'
                //     }
                // ],
                // address:[{ required: true, message: '客户地址不能为空', trigger: 'blur' }],
                name:[{ required: true, message: '报销单名称不能为空', trigger: 'blur' }],
            },
            select_id:"",//当前详情编号

            //以下为报销单详情参数
            isReimbursementDetails:false,
            isReimbursementDetailsLoading:false,
            reimbursementDetailsData:null,
            reimbursementDetailsTableData:[],
            isReimbursementOkButton: false,
            isReimbursementCancelButton: false,
        });
        //获取表格高度（用于滚轮）
        const tableRef = ref();
        const btnRef = ref();
        const queryRef = ref();
        const htmlRef = ref();
        
        //复选的数据id
        const getSelectedRowKeys = (selectedRowKeys) =>{
            data.selectedRowKeys = selectedRowKeys;
        }

        //调用流程 先跳入table公用控件 然后把数据整体输出回来 再进行查询  state为true时说明是控件的回调
        const ctableRef = ref();
        //跳转到table公用控件 status判断是否是新的搜索
        const toQueryList = (status) =>{
            ctableRef.value.queryList(status,data.queryData);//queryList是cTable子组件里的方法
        }
        const tData = (cont) =>{
            data.tableLoading = true;
            getList({
                page: cont[1].page,
                limit: cont[1].limit,
                sort: cont[1].sort,
                name: isOK(cont[0].name)?cont[0].name:"",
                reimbursement_id: isOK(cont[0].reimbursement_id)?cont[0].reimbursement_id:"",
                excel_name:  isOK(cont[0].excel_name)?cont[0].excel_name:"",
                status: isOK(cont[0].status)?cont[0].status:"",
                is_deleted: isOK(cont[0].is_deleted)?cont[0].is_deleted:"",
                desc: cont[1].desc,
            }).then(res =>{
                data.tableLoading = false;
                document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;//搜索后表格回到顶部
                data.tableData = res.data.list;
                data.dataTotal = res.data.total;
            })
        }
        //重置表格数据
        const resetTable = () =>{
            data.queryData.name = "";
            data.queryData.reimbursement_id = "";
            data.queryData.excel_name = "";
            data.queryData.status = "";
            data.queryData.is_deleted = 0;
            //清空表格复选框选中状态
            ctableRef.value.unSelectedRowKeys();
            toQueryList(true);
        }

        //渲染后 加载数据
        onMounted(() => {
            //加载表格数据
            toQueryList(true);

            nextTick(()=>{
                //延迟加载
                setTimeout(() =>{
                    if(store.state.user.isHeadBar){
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                    }else{
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                    }
                },1);
            })

            
        });
        //重置添加的数据
        const resetAddTableData = ()=>{
            data.addModalData={
                product_id:"",
                plan_quantities:"",
                plan_date:undefined,
            }
        }
        //重置modal数据
        const resetModalData = () =>{
            data.modalData = {
                name:"",
                remarks:"",
            };
        }

        //获取产品下拉数据
        const getDownProductList = ()=>{
            data.isProductList = true;
            downProduct().then(res =>{
                data.productList = res.data.list;
                data.isProductList = false;
            })
        }

        //制单-产品选中
        const addOrderProduct = (value)=>{
            data.selectProduct = {};
            for(let i = 0; i < data.productList.length; i++){
                if(data.productList[i].id == value){
                    data.selectProduct = data.productList[i];
                    break;
                }
            }
        }
        //制单-添加产品
        const addModalRef = ref();
        const onAdd = ()=>{
            addModalRef.value.validate().then(() => {
                const newData = {
                    key: Date.now()+"",
                    productId: data.selectProduct.id,
                    productName: data.selectProduct.name || "",
                    productModel: data.selectProduct.model || "",
                    productSize: data.selectProduct.size || "",
                    productWeight: data.selectProduct.weight || "",
                    productColor: data.selectProduct.color || "",
                    productUnit: data.selectProduct.unit || "",
                    planQuantities: data.addModalData.plan_quantities || "",
                    planDate: data.addModalData.plan_date || "",
                };
                let isRepeat = false;//是否已经存在
                for(let i = 0;i<data.tableProductList.length;i++){
                    if(newData.productId===data.tableProductList[i].productId){
                        isRepeat = true;
                    }
                }
                if(isRepeat){
                    //已经存在该商品
                    message.warning("已经添加过该产品");
                }else{
                    //不存在添加
                    data.tableProductList.push(newData);
                }
                
                resetAddTableData();
                //重置表单校验
                addModalRef.value.resetFields();
            });
        }

        //上传Excel文件
        const handleChangeExcel = info => {
            let file = info.file;
            if (file.status !== 'uploading') {
                let isXlsOrXlsx = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                if (!isXlsOrXlsx) {
                    message.error('请上传EXCEL文件!');
                    data.fileExcelList = [];
                    return isXlsOrXlsx;
                }
                let isLt20M = file.size/1024/1024 < 5;
                if (!isLt20M) {
                    message.error('文件不得大于 5MB!');
                    data.fileExcelList = [];
                    return isLt2M;
                }
                //限制只能上传一个文件
                let resFileList = [...info.fileList];
                resFileList = resFileList.slice(-1);
                resFileList = resFileList.map(file => {
                    if (file.response) {
                        file.url = file.response.url;
                    }
                    return file;
                });
                data.fileExcelList = resFileList;
            }
            if (file.status === 'done') {
                // console.log(file);
                // console.log("name:"+file.name+" url:"+file.response.data.url);
                message.success(`${file.name} 上传成功`);
                //https://view.officeapps.live.com/op/view.aspx?src=
                data.fileExcelUrl = 'https://view.officeapps.live.com/op/embed.aspx?src='+encodeURIComponent(file.response.data.url);
                data.fileExcelUrlNew = file.response.data.url;
                data.fileExcelName = file.name;
            } else if (file.status === 'error') {
                message.error(`${file.name} 上传失败`);
            }
        };
        //删除Excel文件
        const handleRemove = file => {
            deleteAvatar({ url:file.response.data.url }).then(res => {
                message.success(`删除成功`);
                const index = data.fileExcelList.indexOf(file);
                const newFileList = data.fileExcelList.slice();
                newFileList.splice(index, 1);
                data.fileExcelUrlNew = "";
                data.fileExcelUrl = "";
                data.fileExcelName = "";
                data.fileExcelList = newFileList;
            });
        };

        //上传图片
        const handleChangePreview = (info) =>{
            // console.log(info);
            let file = info.file;
            if (file.status !== 'uploading') {
                let isImg = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg';
                if (!isImg) {
                    message.error('请上传jpg/png格式的图片文件!');
                    return isImg;
                }
                let isLt5M = file.size/1024/1024 < 5;
                if (!isLt5M) {
                    message.error('文件不得大于 5MB!');
                    return isLt5M;
                }
            }
            if (file.status === 'done') {
                // console.log(file);
                // console.log("name:"+file.name+" url:"+file.response.data.url);
                message.success(`${file.name} 上传成功`);
            } else if (file.status === 'error') {
                message.error(`${file.name} 上传失败`);
            }
        }
        //手动关闭上传
        const handleChangeBeforeUpload = file => {
            // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            // if (!isJpgOrPng) {
            //     message.error('请上传jpg/png格式的图片文件!');
            //     data.fileImgList = [];
            //     return isJpgOrPng;
            // }
            // const isLt5M = file.size / 1024 / 1024 < 5;
            // if (!isLt5M) {
            //     message.error('文件不得大于 5MB!');
            //     for(let i = 0;i < data.fileImgList.length;i++){
            //         if(data.fileImgList[i].uid===file.uid){
            //             data.fileImgList.splice(i,1);
            //         }
            //     }
            //     return isLt5M;
            // }
            // return isJpgOrPng && isLt5M;
        }
        //关闭查看图片
        const handleImgCancel = () => {
            data.previewVisible = false;
        };
        //查看图片
        const handlePreview = async (file) => {
            // console.log(2);
            // console.log(file.response.data.url);
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            data.previewImage = file.response.data.url;
            data.previewVisible = true;
        };
        const handleImgChange = ({ fileList: newFileList }) => {
            data.fileImgList = newFileList;
        };
        //删除图片
        const handlePreviewRemove = (file) =>{
            deleteAvatar({ url:file.response.data.url }).then(res => {
                message.success(`删除成功`);
            });
        }

        //制单-删除选中
        const onDelete = key => {
            data.tableProductList = data.tableProductList.filter(item => item.key !== key);
        };

        //按钮-制单
        const modalRef = ref();
        const reimbursementAdd = ()=>{
            data.modalTitle = "制报销单";
            data.modalType = "add";
  
            resetModalData();
            data.isModal = true;
        }

        //按钮-查看
        const reimbursementSee = ()=>{
            data.modalTitle = "查看报销单";
            //判断是否选择一条数据
            if(data.selectedRowKeys.length==1){
                data.isPass = false;//将状态初始化
                data.select_id = data.selectedRowKeys[0];
                data.isReimbursementDetailsLoading = true;
                details({id:data.selectedRowKeys[0]}).then(res =>{
                    data.reimbursementDetailsData = res.data.reimbursement;
                    data.oneType = data.reimbursementDetailsData.status!=null?data.reimbursementDetailsData.status:0;
                    data.isDeleted = data.reimbursementDetailsData.isDeleted;
                    data.isReimbursementDetails = true;
                    if(data.reimbursementDetailsData.status == 2){
                        data.isPass = true;
                    }
                    data.isReimbursementDetailsLoading = false;
                }).catch((err) => {
                    data.isReimbursementDetailsLoading = false;
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时查询多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //工具-时间格式化
        const getTime = (time) =>{
            return getYyyyddmm(time);
        }

        //对话框-确定
        const handleModal = ()=>{
            modalRef.value.validate().then(() => {
                if(data.fileExcelUrlNew.length<=0){
                    return message.error("请上传报销单");
                }
                // console.log(data.fileImgList);
                let imgList = [];
                for(let i = 0;i < data.fileImgList.length;i++){
                    imgList.push(data.fileImgList[i].response.data.url);
                }
                add({
                    name:data.modalData.name,
                    excel_name:data.fileExcelName,
                    excel_url:data.fileExcelUrlNew,
                    image_list:imgList.join("丨"),
                    remark:data.modalData.remarks
                }).then(res =>{
                    message.success("新增成功");
                    data.isModal = false;
                    data.isModalLoading = false;
                    data.isOkButton = false;
                    data.modalData.name = "";
                    data.modalData.remarks = "";
                    data.fileExcelUrl = "";//清空excel地址
                    data.fileExcelList = [];//清空上传excel列表
                    data.fileExcelUrlNew = "";
                    data.fileImgList = [];
                    resetTable();
                }).catch((err) => {
                    data.isModalLoading = false;
                    message.error(err.data.message);
                });
            })

            // console.log(data.tableProductList.length)
            // modalRef.value.validate().then(() => {
                // if(data.tableProductList.length>0){
                //     if(data.modalType == "add"){
                //         data.isModalLoading = true;
                //         data.tableProductList = data.tableProductList.map((item, index) => {
                //             return {
                //                 productId: item.productId,
                //                 productName: item.productName,
                //                 productModel: item.productModel,
                //                 productSize: item.productSize,
                //                 productWeight: item.productWeight,
                //                 productColor: item.productColor,
                //                 productUnit: item.productUnit,
                //                 planQuantities: item.planQuantities,
                //                 planDate: item.planDate,
                //             }
                //         })
                //         add({
                //             orderProductList:data.tableProductList,
                //             // customer:data.modalData.customer,
                //             // customer_phone:data.modalData.customer_phone,
                //             // address:data.modalData.address,
                //             request:data.modalData.request,
                //             remarks:data.modalData.remarks,
                //         }).then(res =>{
                //             message.success(res.data.sms);
                //             handleCloseModal();
                //             resetTable();
                //         }).catch((err) => {
                //             data.isModalLoading = false;
                //         });
                //     }
                // }else{
                //     message.warning("请添加订单产品");
                // }
            // })
        }

        //对话框-取消
        const handleCloseModal = () =>{
            data.isModal = false;
            data.isModalLoading = false;
            data.isOkButton = false;
            data.modalData.name = "";
            data.modalData.remarks = "";
            data.select_id = "";
            //删除上传的excel
            if(isOK(data.fileExcelUrl)){
                deleteAvatar({ url:data.fileExcelUrl }).then(res => {
                    // data.fileExcelUrl = "";//清空excel地址
                    // data.fileExcelList = [];//清空上传excel列表
                });
            }
            if(data.fileImgList.length>0){
                for(let i = 0;i < data.fileImgList.length;i++){
                    deleteAvatar({ url:data.fileImgList[i].response.data.url }).then(res => {
                        
                    });
                }
            }
            data.fileExcelUrl = "";//清空excel地址
            data.fileExcelList = [];//清空上传excel列表
            data.fileImgList = [];
        }

        //报销单详情对话框-取消
        const handleCloseDetailsModal = ()=>{
            data.isReimbursementDetails = false;
            data.isReimbursementDetailsLoading = false;
            data.select_id = "";
            data.oneType = 0;
            data.isDeleted = true;
            data.reimbursementDetailsData.excelUrl = "";
            // data.reimbursementDetailsData = null;
        }

        //按钮-报废
        const reimbursementRemove = ()=>{
            if(data.selectedRowKeys.length>0){
                Modal.confirm({
                        title: '报废报销单',
                        icon: createVNode(ExclamationCircleOutlined),
                        content: '您是否确定报废报销单',
                        okText: '确认',
                        cancelText: '取消',
                        //确定
                        onOk() {
                            remove({idList:data.selectedRowKeys.toString()}).then(res =>{
                                message.success("报废成功");
                                resetTable();
                            })
                        },
                        // 取消
                        onCancel() {},
                });
            }else{
                message.warning("请选择一条数据");
            }
        }

        // //制单窗口 鼠标移动到产品下拉时，自动打开下拉 开
        // const openDddProductIdSelect = ()=>{
        //     data.isOpenDddProductIdSelect = true;
        // }

        // //制单窗口 鼠标移动到产品下拉时，自动打开下拉 关
        // const colseDddProductIdSelect = ()=>{
        //     data.isOpenDddProductIdSelect = false;
        // }

        //审核
        const toReimbursementExamine = (type)=>{//0财务未审查，未审核1财务已审查，未审核2批准3驳回4已报销
            let oneDate = {};
            for(let i = 0;i < data.tableData.length;i++){
                if(data.selectedRowKeys[0]===data.tableData[i].id){
                    oneDate = data.tableData[i];
                }
            }
            if(data.btnMenu.examine){
                if(oneDate.status == 4){
                    return message.error("该报销单已报销，不可再操作");
                }
                if(oneDate.status == 3 && type == 3){
                    return message.error("该报销单不需要重复操作，已经被驳回");
                }

                examine({id:data.select_id,type:type}).then(res =>{
                    message.success(res.data.sms);
                    handleCloseDetailsModal();
                    resetTable();
                })
            }else{
                if(oneDate.status==2 && type == 4){
                    //点击 已报销按钮
                    examine({id:data.select_id,type:type}).then(res =>{
                        message.success(res.data.sms);
                        handleCloseDetailsModal();
                        resetTable();
                    })
                    return;
                }else{
                    if(oneDate.status<2 && type == 4){
                        return message.error("该报销单还没到该流程");
                    }
                    if(oneDate.status==3 && type == 4){
                        return message.error("该报销单已被驳回");
                    }
                    if(oneDate.status==4 && type == 4){
                        return message.error("该报销单已被报销，无需重复操作");
                    }
                }
                if(oneDate.status==1 && type==1){
                    return message.error("该报销单不需要重复操作，已经被审查过");
                }
                if(oneDate.status==3 && type==3){
                    return message.error("该报销单不需要重复操作，已经被驳回");
                }
                examine({id:data.select_id,type:type}).then(res =>{
                    message.success(res.data.sms);
                    handleCloseDetailsModal();
                    resetTable();
                })
                
            }
        }

        //发送短信提醒
        const reimbursementSMS = ()=>{
            SMS().then(res =>{
                if(res.code==20000){
                    message.success(res.message);
                }else{
                    message.error(res.message);
                }
            })
        }

        //下载excel模版
        const toExcel = ()=>{
            window.open("https://www.yuxisuye.cn/appyuxi/reimbursement.xlsx");
        }

        //下载RAR
        const toRAR = (name,url,type) =>{
            let u = url;
            if(type==2){//详情里面下载压缩包
                data.isReimbursementDetailsLoading = true;
                toFileDown({url:u}).then((res) => {
                    const blob = new Blob([res]);//返回一个新的blob对象
                    let fileName = name;//下载文件名
                    const downLoadElement = document.createElement('a');//创建a标签
                    downLoadElement.style.display = 'none';//a标签样式为隐藏
                    const href = window.URL.createObjectURL(blob);//创建window临时地址
                    downLoadElement.href = href;//跳转地址
                    downLoadElement.setAttribute('download', fileName);
                    document.body.appendChild(downLoadElement);//将指定的dom添加的body之后
                    downLoadElement.click();//点击事件
                    document.body.removeChild(downLoadElement);//移除dom
                    window.URL.revokeObjectURL(href);//释放url地址
                    data.isReimbursementDetailsLoading = false;
                }).catch((err) => {
                    message.error("下载错误");
                    data.isReimbursementDetailsLoading = false;
                })
            }else{
                data.tableLoading = true;
                toFileDown({url:u}).then((res) => {
                    const blob = new Blob([res]);//返回一个新的blob对象
                    let fileName = name;//下载文件名
                    const downLoadElement = document.createElement('a');//创建a标签
                    downLoadElement.style.display = 'none';//a标签样式为隐藏
                    const href = window.URL.createObjectURL(blob);//创建window临时地址
                    downLoadElement.href = href;//跳转地址
                    downLoadElement.setAttribute('download', fileName);
                    document.body.appendChild(downLoadElement);//将指定的dom添加的body之后
                    downLoadElement.click();//点击事件
                    document.body.removeChild(downLoadElement);//移除dom
                    window.URL.revokeObjectURL(href);//释放url地址
                    data.tableLoading = false;
                }).catch((err) => {
                    message.error("下载错误");
                    data.tableLoading = false;
                })
            }
        }

        return {
            setSortedInfo,
            columns,
            tableRef,
            btnRef,
            queryRef,
            htmlRef,
            ctableRef,
            data,
            getSelectedRowKeys,
            toQueryList,
            tData,
            resetTable,

            getDownProductList,
            addOrderProduct,
            onAdd,
            handleChangeExcel,
            handleRemove,
            headers,

            handleChangePreview,
            handleChangeBeforeUpload,
            handleImgCancel,
            handlePreview,
            handleImgChange,
            handlePreviewRemove,

            onDelete,

            resetAddTableData,

            resetModalData,
            addModalRef,
            modalRef,
            reimbursementAdd,
            reimbursementSee,
            handleModal,
            handleCloseModal,

            handleCloseDetailsModal,

            getTime,

            reimbursementRemove,
            toReimbursementExamine,
            reimbursementSMS,
            toExcel,
            toRAR,

            // openDddProductIdSelect,
            // colseDddProductIdSelect,
        }
   }
   
}
</script>
<style lang='scss' scoped>
.html{
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.query{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px 0 20px;
}
::v-deep .btn{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px;
}
.btn_style{
    margin-right: 12px;
}
.table{
    width: calc(100% - 40px);
    // height: calc(100% - 102px);
    margin-left: 20px;
}
.detailsCol{
    height: 16px;
    line-height: 16px;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: right;
}
.addTable{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.STYLE7 {font-size: 16px; font-family: "宋体"; }
.STYLE8 {font-size: 15px; font-family: "宋体"; }
.td1{
	width:172px;
	word-wrap:break-word;
	word-break:break-all;
	text-align:center;
}
.td2{
	width:106px;
	word-wrap:break-word;
	word-break:break-all;
	text-align:center;
}
.td3{
	width:100%;
    font-size: 16px;
    text-align: left;
    padding-left:5px;
	word-wrap:break-word;
	word-break:break-all;
	text-align:left;
}
.detailsTitle{
    width: 100%;
    height: 30px;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 30px;
}
.long{
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.reimbursementName{
    width: 100%;
    height: 64px;
}
.excel{
    width: 100%;
    height: 800px;
}
.excelLeft{
    float: left;
    width: calc(100% - 255px);
    height: 800px;
    border-right: 1px solid #eee;
}
.excelRight{
    float: left;
    width: 254px;
    height: 800px;
    overflow: hidden;
}
.excelRightTop{
    width: 100%;
    height: 70px;
}
.excelRightImg{
    width: calc(100% - 16px);
    height: 730px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-left: 16px;
}
.excelRightImg::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.excelRightImg::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 0px rgba(230, 230, 230, 0.8);
  background: rgba(230, 230, 230, 0.8);
}
.excelRightImg::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 1);
}
.excelBtn{
    width: 254px;
    height: 32px;
}
.excelBtnIn{
    float: left;
    width: 100%;
    height: 32px;
    margin-left: 16px;
}

.remark{
    width: 100%;
    height: 100px;
}

::v-deep .ant-upload-list-picture-card .ant-upload-list-item{
    float: left;
    width: 236px;
    height: 104px;
    margin: 0 8px 8px 0;
}
::v-deep .ant-upload-list-picture-card-container{
    float: left;
    width: 236px;
    height: 104px;
    margin: 0 8px 8px 0;
}

.details{
    width: 100%;
    height: 670px;
}
.detailsLeft{
    float: left;
    width: calc(60% - 17px);
    height: 670px;
}
.detailsRight{
    float: left;
    width: calc(40% - 17px);
    height: 670px;
    border-left: 1px solid #eee;
    margin-left: 16px;
    padding-left: 16px;
    overflow: auto;
}
.detailsRight::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.detailsRight::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 0px rgba(198, 199, 202, 0.7);
  background: rgba(198, 199, 202, 0.7);
}
.detailsRight::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 1);
}
.detailsRightName{
    width: 100%;
    height: 40px;
    font-size: 20px;
    line-height: 40px;
}
.detailsRightRAR{
    width: 100%;
    height: 30px;
    font-size: 18px;
    line-height: 30px;
    color: #cc5a46;
    cursor: pointer;
}
.detailsRightRemark{
    width: 100%;
    height: auto;
    font-size: 15px;
    margin-top: 6px;
}
.detailsRightImg{
    width: 100%;
    height: auto;
}

.foot{
    margin-top: 16px;
    width: 100%;
    height: 32px;
}
.fontIn{
    float: right;
    width: calc(40% - 17px);
    height: 32px;
}
</style>